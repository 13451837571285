import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Form, Input, Select, Skeleton } from 'antd';
import CarService from '../../services/CarService';
import DriverService from '../../services/DriverService';
import DateMaskInput from '../../components/MaskInput/DateMaskInput';
import PhoneMaskInput from '../../components/MaskInput/PhoneMaskInput';
import PayDayMaskInput from '../../components/MaskInput/PayDayMaskInput';

const Wrapper = styled.div`
display: flex;
justify-content: center;
margin-top: 24px;
max-width: 100%;
`

const { TextArea } = Input;


const EditDriver = ({ setOpen, getDrivers, driver, userCompany }) => {
    const [cars, setCars] = useState();
    const [formEdit] = Form.useForm();

    useEffect(() => {
        getCarsApi()
    }, [])

    const getCarsApi = async () => {
        const data = await CarService.get({ company_id: userCompany });
        setCars(data.data)
    }

    const onFinish = async (values) => {
        await DriverService.createOrEdit(values);
        setOpen(false);
        await getDrivers();
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <Wrapper>
            {cars ?
                <Form
                    name="edit"
                    form={formEdit}
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 22,
                    }}
                    labelAlign='left'
                    style={{
                        width: 600,
                    }}
                    initialValues={{
                        ...driver,
                        car: driver.car._id
                    }}
                    layout='horizontal'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="ТС"
                        name="car"
                    >
                        {cars ? <Select
                            showSearch
                            placeholder="Выбрать авто"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={cars ? cars.map(el => { return { value: el._id, label: `${el.number} (${el.model})` } }) : null}
                        /> : <Skeleton
                            avatar
                            paragraph={{
                                rows: 4,
                            }}
                        />}
                    </Form.Item>

                    <Form.Item
                        label="ФИО"
                        name="name"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Телефон"
                        name="phone"
                    >
                        <PhoneMaskInput />
                    </Form.Item>

                    <Form.Item
                        label="Расчетный день"
                        name="startDayToPay"
                    >
                        <PayDayMaskInput />
                    </Form.Item>

                    <Form.Item
                        label="Дата рождения"
                        name="birth"
                    >
                        <DateMaskInput />
                    </Form.Item>

                    <Form.Item
                        label="Номер паспорта"
                        name="passNumber"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Дата выдачи"
                        name="passDate"
                    >
                        <DateMaskInput />
                    </Form.Item>
                    <Form.Item
                        label="Кем выдан"
                        name="passWho"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Адрес прописки"
                        name="adress"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Номер ВУ"
                        name="driverPassNumber"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Дата выдачи ВУ"
                        name="driverPassDate"
                    >
                        <DateMaskInput />
                    </Form.Item>


                    <Form.Item
                        label="Комментарии"
                        name="comment"
                    >
                        <TextArea rows={4} />
                    </Form.Item>

                    <Form.Item
                        label="ID"
                        name="_id"
                        hidden
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 20,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                    </Form.Item>
                </Form> : null}
        </Wrapper>
    );
};

export default EditDriver;