import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import OrdersChart from './Charts/OrdersChart';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const Wrapper = styled.div`
    margin-top: 16px;
    display: flex;
    gap: 18px;
    justify-content: space-around;
`

const ChartWrapper = styled.div`
    padding: 20px 35px 0 0;
    background-color:#001529;
    border-radius: 18px;
    
    
`

const MainPage = ({ openNotification }) => {

    const userCompany = useSelector((state) => state.userInfo.user.user.company_id);

    useEffect(() => {
        document.title = "Главная страница - ГрузДок - сервис учета грузоперевозок";
    }, []);



    return (
        <Wrapper>
            <ChartWrapper>
                <OrdersChart userCompany={userCompany} />
            </ChartWrapper>
        </Wrapper>
    );
};


export default MainPage;