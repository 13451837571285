import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { X, Clipboard, CashCoin, ArrowClockwise } from 'react-bootstrap-icons';
import { Modal, Space, Table, Tooltip, Button } from 'antd';
import AddNewDriver from './AddNewDriver';
import EditDriver from './EditDriver';
import styled from 'styled-components';
import { removeDuplicates } from '../../components/getters';


const YellowRound = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background-color: #ffda4d;
`
const GreenRound = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background-color: #38761d;
`

const IconButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
`

const StatusWrapper = styled.div`
    display: flex;
    justify-content: center;
`


const DriversTable = ({ getDrivers, deleteDriver, data, setOpen, open, userCompany, openNotification, types, recoverDriver }) => {

    const [openEdit, setOpenEdit] = useState(false);
    const [openDriver, setOpenDriver] = useState('')

    useEffect(() => {
    }, [data])

    const handleCancel = () => {
        setOpen(false);
    }

    const handleCancelEdit = () => {
        setOpenEdit(false);
    }

    const copyToClipBoard = (data) => {
        navigator.clipboard.writeText(`
ФИО: ${data.name}
Дата рождения ${data.birth}
Телефон: ${data.phone} 
Паспорт: ${data.passNumber}
Кем выдан: ${data.passWho}
Дата выдачи: ${data.passDate}
Прописка: ${data.adress}
ВУ: ${data.driverPassNumber} ${data.driverPassDate}
ТС: ${data.car.model} ${data.car.number}`);
    }

    const ButtonEditType = ({ record }) => {
        const typeName = types.find(el => el._id === record.type).typeName
        const typeNameString = typeName === 'Уволенные' ? 'Восстановить' : 'Уволить'
        return <>
            <Tooltip title={typeNameString}>
                <IconButton onClick={() => {
                    Modal.confirm({
                        onOk: () => {
                            typeName === 'Уволенные' ? recoverDriver(record) : deleteDriver(record);
                            openNotification('Успешно!', `Водитель ${typeName === 'Уволенные' ? 'восстановлен!' : 'уволен!'}`, 'info')
                        },
                        title: typeNameString,
                        content: `Подтвердите действие`,
                        footer: (_, { OkBtn, CancelBtn }) => (
                            <>
                                <CancelBtn />
                                <OkBtn />
                            </>
                        ),
                    })
                }} style={{ 'backgroundColor': 'rgba(29, 87, 151, 0)', 'border': 'none', 'width': '20px', 'height': '20px' }}
                    icon={
                        typeName === 'Уволенные' ? <ArrowClockwise style={{ 'color': '#38761d', 'width': '18px', 'height': '18px' }} /> : <X style={{ 'color': '#ef423e', 'width': '18px', 'height': '18px' }} />
                    } />
            </Tooltip>
        </>
    }

    const columns = useMemo(
        () => [
            {
                title: 'Автомобиль',
                dataIndex: 'car',
                key: 'car',
                width: 40,
                render: (text) => text.number
            },
            {
                title: 'Объем',
                dataIndex: 'car',
                key: 'car',
                width: 40,
                filterSearch: true,
                filters: data ? removeDuplicates(data.map(el => ({ text: el.car.volume, value: el.car.volume }))) : null,
                onFilter: (value, record) => record?.car?.volume === value,
                render: (text) => `${text.volume}м3`
            },
            {
                title: 'Статус',
                dataIndex: 'status',
                key: 'status',
                width: 40,
                render: (text) => <StatusWrapper>{text === 'needToPay' ? <Tooltip title="Требуется оплата"> <YellowRound /> </Tooltip> : <GreenRound />}</StatusWrapper>
            },
            {
                title: 'Имя',
                dataIndex: 'name',
                key: 'name',
                filterSearch: true,
                filters: data ? removeDuplicates(data.map(el => ({ text: el.name, value: el._id }))) : null,
                onFilter: (value, record) => record ? record._id.startsWith(value) : null,
                width: 40,
            },
            {
                title: 'Дата рождения',
                dataIndex: 'birth',
                key: 'birth',
                width: 40,
            },
            {
                title: 'Номер паспорта',
                dataIndex: 'passNumber',
                key: 'passNumber',
                width: 40,
            },
            {
                title: 'Дата выдачи',
                dataIndex: 'passDate',
                key: 'passDate',
                width: 40,
            },
            {
                title: 'Кем выдан',
                dataIndex: 'passWho',
                key: 'passWho',
                width: 40,
            },
            {
                title: 'Прописка',
                dataIndex: 'adress',
                key: 'adress',
                width: 40,
            },
            {
                title: 'Номер ВУ',
                dataIndex: 'driverPassNumber',
                key: 'driverPassNumber',
                width: 40,
            },
            {
                title: 'Дата выдачи ВУ',
                dataIndex: 'driverPassDate',
                key: 'driverPassDate',
                width: 40,
            },
            {
                title: 'Комментарии',
                dataIndex: 'comment',
                key: 'comment',
                width: 40,
            },
            {
                title: 'Действия',
                dataIndex: 'operation',
                width: 60,
                render: (_, record) => (
                    <Space>
                        <ButtonEditType record={record} />
                        <Tooltip title="Копировать данные">
                            <IconButton onClick={() => copyToClipBoard(record)}
                                style={{ 'backgroundColor': 'rgba(29, 87, 151, 0)', 'border': 'none', 'width': '20px', 'height': '20px' }}
                                icon={
                                    <Clipboard style={{ 'color': '#bfae65', 'width': '18px', 'height': '18px' }} />
                                } />
                        </Tooltip>
                        <Tooltip title="Зарплата">
                            <IconButton onClick={() => navigate(`/payments?id=${record._id}`)}
                                style={{ 'backgroundColor': 'rgba(29, 87, 151, 0)', 'border': 'none', 'width': '20px', 'height': '20px' }}
                                icon={
                                    <CashCoin style={{ 'backgroundColor': 'rgba(29, 87, 151, 0)', 'color': '#38761d', 'width': '18px', 'height': '18px' }} />
                                } />
                        </Tooltip>
                    </Space>
                ),
            }
        ],
        [],
    );

    const navigate = useNavigate()

    return (
        <>
            {
                data &&
                <>
                    <Table
                        columns={columns}
                        dataSource={data}
                        rowClassName={(record, index) => index % 2 === 0 ? 'even-row-table' : 'odd-row-table'}
                        onRow={(record) => {
                            return {
                                onDoubleClick: async () => {
                                    setOpenDriver(record);
                                    setOpenEdit(true)
                                },
                            };
                        }}
                    />

                    <Modal
                        title="Добавление водителя"
                        open={open}
                        destroyOnClose={true}
                        onCancel={handleCancel}
                        footer={null}
                        width={500}
                        style={{ top: 10 }}
                    >
                        <AddNewDriver setOpen={setOpen} getDrivers={getDrivers} userCompany={userCompany}  types={types}/>
                    </Modal>

                    <Modal
                        title="Редактирование водителя"
                        open={openEdit}
                        destroyOnClose={true}
                        onCancel={handleCancelEdit}
                        footer={null}
                        width={500}
                        style={{ top: 10 }}
                    >
                        <EditDriver setOpen={setOpenEdit} getDrivers={getDrivers} driver={openDriver} userCompany={userCompany} types={types}/>
                    </Modal>
                </>
            }
        </>

    )
};

export default DriversTable;