import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Select, DatePicker, ConfigProvider, Form, Modal, Collapse } from 'antd';
import OrdersService from '../../services/OrdersService';
import DriverService from '../../services/DriverService';
import ru_RU from 'antd/lib/locale/ru_RU';
import moment from 'moment';
import { ButtonPrimary } from '../../styles/buttons';
import RoutesTable from './RoutesTable';
import styled from 'styled-components';
import PaymentTable from './PaymentTable';
import AddDebt from './AddDebt';
import driversService from '../../services/DriverService';
import RoutesTableFull from './RoutesTableFull';
import PaymentCalendar from './PaymentCalendar';
import { useSelector } from 'react-redux';

const ButtonsWrapper = styled.div`
    display: flex;
    gap: 8px;
`

const ButtonPlusInterval = styled.div`
    display: flex;
    flex-direction: row;
`

const Wrapper = styled.div`
    display: flex;
    margin: 16px 0;
`

const WrapperItem = styled.div`
    min-width: 600px;
`

const TitleBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Payments = ({ socket, openNotification }) => {
    const [drivers, setDrivers] = useState();
    const [routes, setRoutes] = useState();
    const [fullRoutes, setFullRoutes] = useState();
    const [openDebtModal, setOpenDebtModal] = useState();
    const [openRoutesModal, setOpenRoutesModal] = useState();
    const [driver, setDriver] = useState();
    const [paymentsInfo, setPaymentsInfo] = useState();
    const [isPaidMonth, setPaidMonth] = useState(false);
    const [currentDriver, setCurrentDriver] = useState();
    const [chooseForm] = Form.useForm();
    const [driverPaymentMonth, setDriverPaymentMonth] = useState();

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const driverId = params.get("id");

    const monthDate = useRef();
    const dateForFinish = useRef();
    const currentId = useRef();
    const currentValues = useRef();

    const userCompany = useSelector((state) => state.userInfo.user.user.company_id);

    const roomName = `Payments ${userCompany}`;

    const getSocketConnect = useCallback( async () => {
        socket.on('update', async (data) => {
            // await getRegistry();
        });
    }, [socket])

    const getDrivers = useCallback( async () => {
        const res = await DriverService.get({ company_id: userCompany });
        setDrivers(res.data)
    }, [userCompany])

    useEffect(() => {
        getDrivers();
        document.title = "Заработная плата - ГрузДок - сервис учета грузоперевозок"
        socket.emit('enterRoom', roomName);
        getSocketConnect();
        return () => {
            socket.emit('leaveRoom', roomName);
        };
    }, [getDrivers, getSocketConnect, roomName, socket])





    const onFinishHadler = async (values = currentValues.current) => {
        currentValues.current = values;
        dateForFinish.current = values.date;
        setPaidMonth(false)
        const startDate = moment(new Date(values.date.$d)).utc().set({
            hour: 0,
            minute: 0,
          }).format('YYYY-MM-DD HH:mm');
        const endDate = moment(new Date(values.date.$d)).utc().add(1, 'months').subtract(1, 'days').set({
            hour: 23,
            minute: 59,
          }).format('YYYY-MM-DD HH:mm');
        const date = {
            startDate: startDate,
            endDate: endDate
        }
        const dateForSave = startDate.split(' ')[0].split('-');
        await getPaymentsInfo(values.driver);
        const monthPayment = await driversService.getPaymentMonth({ driver: values.driver, year: +dateForSave[0], month: +dateForSave[1], day: +dateForSave[2] })
        setDriverPaymentMonth(monthPayment.data);
        monthDate.current = date;
        setDriver(values.driver);
        
        const isFind = monthPayment.data.find(el => (el.year === +dateForSave[0]) && (el.month === +dateForSave[1]) && (el.day === +dateForSave[2]))
        if (isFind) {
            setPaidMonth(true)
            setFullRoutes(isFind.routes);
            const tableData = isFind.routes.map((el, index) => ({
                key: index,
                date: el.date,
                routeA: el.routeA,
                routeB: el.routeB,
                driver_bet: el.driver_bet
            }))
            setRoutes(tableData);
            currentId.current = isFind._id;

        } else {
            setPaidMonth(false)
            const res = await OrdersService.getOrdersForPayment({ date: { $gte: date.startDate, $lte: date.endDate }, driver: values.driver })
            const tableData = res.data.map((el, index) => ({
                key: index,
                date: el.date,
                routeA: el.routeA,
                routeB: el.routeB,
                driver_bet: el.driver_bet
            }))
            setFullRoutes(res.data);
            setRoutes(tableData);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleCancelDebtModal = () => {
        setOpenDebtModal(false);
    }

    const handelOpenDebtModal = () => {
        setOpenDebtModal(true);
    }

    const handleOpenRouteModal = () => {
        setOpenRoutesModal(true);
    }

    const handleCancelRouteModal = () => {
        setOpenRoutesModal(false);
    }

    const getPaymentsInfo = async (driver) => {
        const res = await driversService.getPayment({ driver: driver })
        const data = res.data.map((el, index) => ({
            ...el,
            date: moment(el.date).format('DD/MM/YYYY'),
            key: index,
        }))
        setPaymentsInfo(data);
        return data;
    }

    const disabledDate = (current) => {
        return current.date() !== currentDriver.startDayToPay;
    };

    const setSelectedDriver = async (data) => {
        const driver = drivers.find(el => el._id === data)
        setCurrentDriver(driver)
        if (!driver.startDayToPay) {
            openNotification('Ошибка!', 'У водителя не указан расчетный день! Отредактируйте водителя', 'info')
        } else {
            setPaymentsInfo()
        }
        chooseForm.setFieldValue('date', null)
    }

    const renderExtraFooter = () => {
        return `Доступные даты: ${currentDriver.startDayToPay} число каждого месяца`;
      };

    return (
        <>
            {
                drivers &&
                <Form
                    form={chooseForm}
                    name="basic"
                    initialValues={
                        driverId ?
                            {
                                driver: driverId
                            } : null
                    }
                    labelCol={{
                        span: 5,
                    }}
                    wrapperCol={{
                        span: 30,
                    }}
                    labelAlign='left'
                    style={{
                        width: 800,
                    }}
                    layout='horizontal'
                    onFinish={onFinishHadler}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >

                    <Form.Item
                        label="Выберите водителя"
                        name="driver"
                        rules={[
                            {
                                required: true,
                                message: 'Водитель не выбран'
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Водитель"
                            optionFilterProp="children"
                            options={drivers.map(el => ({ value: el._id, label: el.name }))}
                            onChange={(data) => setSelectedDriver(data)}
                        />
                    </Form.Item>

                    {currentDriver?.startDayToPay ?
                        <ButtonPlusInterval>
                            <ConfigProvider locale={ru_RU}>
                                <Form.Item
                                    label="Выберите дату начала"
                                    name="date"
                                    style={{
                                        width: '100%'
                                    }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Даты не выбраны'
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        disabledDate={disabledDate}
                                        renderExtraFooter={renderExtraFooter}
                                    />
                                </Form.Item>
                            </ConfigProvider>

                            <ButtonPrimary type="primary" htmlType="submit">
                                Показать
                            </ButtonPrimary>

                        </ButtonPlusInterval> : null
                    }

                </Form>
            }

            <ButtonsWrapper>
                <ButtonPrimary onClick={handelOpenDebtModal}>
                    Добавить заметку
                </ButtonPrimary>

                <ButtonPrimary>
                    Блокнот
                </ButtonPrimary>
            </ButtonsWrapper>
            <Wrapper>
                <WrapperItem>
                    {
                        driver && routes && paymentsInfo &&
                        <>
                            <h3>Заметки</h3>
                            <PaymentTable driver={drivers.find(el => el._id === driver)} getPaymentsInfo={getPaymentsInfo} paymentsInfo={paymentsInfo.filter(el => el.monthDate === null)} monthDate={monthDate.current} isPaidMonth={isPaidMonth} />
                        </>

                    }
                </WrapperItem>

                <WrapperItem>
                    {
                        routes && paymentsInfo &&
                        <>
                            <TitleBlock>
                                <h3>Маршурты</h3>
                                <ButtonPrimary onClick={handleOpenRouteModal}>
                                    Раскрыть
                                </ButtonPrimary>
                            </TitleBlock>

                            <RoutesTable
                                style={{ 'width': '600px' }}
                                driver={drivers.find(el => el._id === driver)}
                                data={routes}
                                paymentsInfo={paymentsInfo.filter(el => el.monthDate === monthDate.current.startDate)}
                                getPaymentsInfo={getPaymentsInfo}
                                monthDate={monthDate}
                                fullRoutes={fullRoutes}
                                isPaidMonth={isPaidMonth}
                                onFinishHadler={onFinishHadler}
                                dateForFinish={dateForFinish.current}
                                currentId={currentId.current}
                                driverPaymentMonth={driverPaymentMonth[0]?.paymentHistory}
                            />
                        </>
                    }
                </WrapperItem>
            </Wrapper>

            <Modal
                title="Новая заметка"
                open={openDebtModal}
                onCancel={handleCancelDebtModal}
                footer={null}
                destroyOnClose={true}
                width={400}
                style={{ top: 10 }}
            >
                <AddDebt setOpen={setOpenDebtModal} driver={driver} getPaymentsInfo={getPaymentsInfo} />
            </Modal>

            <Modal
                title="Рейсы"
                open={openRoutesModal}
                onCancel={handleCancelRouteModal}
                footer={null}
                destroyOnClose={true}
                width={'100%'}
                style={{ top: 10 }}
            >
                <RoutesTableFull
                    setOpen={setOpenRoutesModal}
                    data={fullRoutes}
                    driver={driver}
                    currentId={currentId.current}
                    getData={onFinishHadler}
                    isPaidMonth={isPaidMonth}
                    socket={socket}
                />
            </Modal>

            {
                driverPaymentMonth &&
                <Collapse
                    items={[
                        {
                            key: '1',
                            label: 'Календарь заработных плат',
                            children: <PaymentCalendar driver={driver} />,
                        },
                    ]}
                />
            }
        </>
    );
};

export default Payments;