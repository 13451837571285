import React, { useCallback, useEffect, useState } from 'react';
import { Line, XAxis, YAxis, Tooltip, Legend, CartesianGrid, LineChart } from 'recharts';
import OrdersService from '../../../services/OrdersService';
import { getOrders } from '../../../components/getters';
import dayjs from 'dayjs';

const OrdersChart = ({ userCompany }) => {

    const getOrdersForChart = useCallback(async () => {
        const getOrdersThisWeek = await OrdersService.getOrdersCount(
            {
                company_id: userCompany, dateStart: dayjs().startOf('month').subtract(10, 'day'), dateEnd: dayjs().add(10, 'day')
            }
        ).then(res => res.data)
        setOrdersForChart(getData(getOrdersThisWeek))
    }, [userCompany]);

    const [ordersForChart, setOrdersForChart] = useState([]);

    useEffect(() => {
        getOrdersForChart();

    }, [getOrdersForChart])

    const getData = (getOrders) => {
        const dataForChart = getOrders.map(el => ({
            day: el._id.day,
            name: `${String(el._id.day).padStart(2, '0')}.${String(el._id.month).padStart(2, '0')}`,
            uv: el.count
        }))
        return dataForChart;
    }


    return (
        <>
            {
                ordersForChart.length > 0 ?
                    <LineChart
                        width={500}
                        height={400}
                        data={ordersForChart}
                    >
                        <CartesianGrid strokeDasharray="1 1"  stroke="#8884d8" />
                        <XAxis dataKey="name"  stroke="#8884d8" />
                        <YAxis  stroke="#8884d8" />
                        <Tooltip />
                        <Legend  wrapperStyle={{ color: 'red' }}  stroke="#8884d8" />
                        <Line type="monotone" dataKey="uv" name="Маршруты" stroke="green" payload={[{ name: '05-01', value: 12, unit: 'kg' }]} />
                    </LineChart > : 'zxcxzc'
            }
        </>
    );
};

export default OrdersChart;