import { Button, Form, Input, InputNumber, Space, Tooltip, Pagination } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useCallback, useEffect, useState } from 'react';
import { ArrowClockwise, DashCircleDotted, PlusCircleDotted } from 'react-bootstrap-icons';
import styled from 'styled-components';
import RegistryService from '../../../services/RegistryService';
import DocksService from '../../../services/DocsService';
// import { DWN_URL } from '../../../http';
import { ButtonPrimary } from '../../../styles/buttons';
import OrdersService from '../../../services/OrdersService';

const Wrapper = styled.div`
    margin-top: 16px;
    gap: 20px;
    display: flex;
    flex-direction: column;
`

const IconButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
`

const Bill = ({ data, setOrdersToDocks, documents, checkDocs, createDocs }) => {

    const [RegsItems, setItems] = useState();
    // const [isSumFits, setIsSumFits] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15; // количество элементов на странице

    const [form] = Form.useForm();

    const createData = useCallback( async (items) => {
        const ordersFromCompleted = await DocksService.findDocsRegistryItem({registryRef: data._id});
        if (ordersFromCompleted.length > 0) {
            setItems({ orders: items.ordersToDocs });
        } else {
            const orders = await OrdersService.getOrdersForPayment({registryRef: data._id});
            const res = orders.data.map(el => {
                let routes = el.routes.map(item => item.filter(foo => foo.length > 0));
                routes = [].concat(...routes);
                return {
                    name: routes.join(' - '),
                    qty: 1,
                    unit: 'шт.',
                    price: +el.bet,
                    sum: el.bet * 1,
                }
            })
            setItems({ orders: res });
        }
    },[data._id])

    useEffect(() => {
        createData();
    }, [createData])

    const onFinish = async (values) => {
        await RegistryService.createOrEdit({ _id: data._id, ordersToDocs: values.ordersToDocs });
        setOrdersToDocks(data._id)
        createData();
        await createDocs();
        checkDocs();
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const deleteItem = (arr) => {
        const startSum = data.orders.reduce((a, b) => a + +b.bet, 0)
        const endSum = arr.reduce((a, b) => a + +b.sum, 0)
        if (startSum !== endSum) {
            // setIsSumFits(false)
        }
    }

    const onFormLayoutChange = () => {
        const values = form.getFieldValue();
        const newval = values.ordersToDocs.map(el => ({
            ...el,
            sum: el.qty * el.price
        }))
        form.setFieldsValue({
            ordersToDocs: newval
        })
    }

    const resetValues = () => {
        const res = data.orders.map(el => ({
            name: el.route,
            qty: 1,
            unit: 'шт.',
            price: +el.bet,
            sum: el.bet * 1,
        }))

        form.setFieldsValue({
            ordersToDocs: res
        })
        setItems(res);
    }


    return (
        <Wrapper>
            <Space>
                <h5>Товары (работы, услуги)</h5>
                {
                    data.status !== 'Paid' &&
                    <Tooltip title="Сбросить">
                        <IconButton onClick={resetValues} alt='Сбросить' icon={<ArrowClockwise />}></IconButton>
                    </Tooltip>
                }

            </Space>

            {
                RegsItems &&
                <Form
                    form={form}
                    layout='horizontal'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    disabled={data.status === 'Paid' ? true : false}
                >
                    <Form.List name="ordersToDocs"
                        initialValue={RegsItems.orders}
                    >
                        {(fields, { add, remove }) => (
                            <>
                                {fields.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                                    .map(({ key, name, ...restField }) => (
                                        <Space
                                            key={key}
                                            style={{
                                                display: 'flex',
                                                width: '100%'
                                            }}
                                            align="start"
                                        >
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'name']}
                                                style={{
                                                    width: '400px',
                                                }}
                                            >
                                                <TextArea rows={1} placeholder="Наименование" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                onChange={onFormLayoutChange}
                                                name={[name, 'qty']}
                                                initialValue={1}
                                            >
                                                <Input type="number" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'unit']}
                                                initialValue={'шт.'}
                                            >
                                                <Input disabled />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'price']}
                                                onChange={onFormLayoutChange}
                                            >
                                                <Input type="number" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'sum']}
                                            >
                                                <InputNumber disabled />
                                            </Form.Item>
                                            <DashCircleDotted onClick={() => {
                                                remove(name)
                                                const arr = RegsItems.orders;
                                                arr.splice(name, 1);
                                                deleteItem(arr)
                                            }} />
                                        </Space>
                                    ))}
                                {
                                    currentPage === Math.ceil(fields.length / itemsPerPage) ?
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusCircleDotted />}>
                                                Добавить
                                            </Button>
                                        </Form.Item> : null
                                }

                                <Pagination
                                    current={currentPage}
                                    defaultPageSize={itemsPerPage}
                                    total={fields.length}
                                    onChange={(page) => setCurrentPage(page)}
                                />
                            </>
                        )}
                    </Form.List>

                    <Form.Item>
                        {
                            data.status !== 'Paid' &&
                            <ButtonPrimary type="primary" htmlType="submit">
                                Сформировать
                            </ButtonPrimary>
                        }
                    </Form.Item>

                </Form>
            }
        </Wrapper >
    );
};

export default Bill;