import React, { useEffect, useState, useMemo } from 'react';
import './App.css';
import { useDispatch } from 'react-redux';
import AuthService from './services/AuthService';
import { checkIsAuth } from './redux/reducers/userReducer';
import { Link } from 'react-router-dom';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import { Layout, notification } from 'antd';
import MenuButton from './components/Header/MenuButton';

import { Button, Form, Input } from 'antd';
import styled from 'styled-components';
import HeaderWrapper from './components/Header/Header';
import MainPage from './pages/MainPage/MainPage';
import Companies from './pages/Сompanies/Сompanies';
import Registry from './pages/Registry/Registry';
import Agents from './pages/Agents/Agents';
import Orders from './pages/Orders/Orders';
import Drivers from './pages/Drivers/Drivers';
import logo from './img/logo.svg';
import Settings from './pages/Settings/Settings';
import Payments from './pages/Payments/Payments';
import { fetchLogin } from './redux/reducers/userReducer';


import socketIO from 'socket.io-client';
import MainAPage from './pages/MainAPage/MainAPage';
import moment from 'moment';
import Planning from './pages/Planning/Planning';
import Bucket from './pages/Bucket/Bucket';
import CarsPage from './pages/Cars/CarsPage';


const socket = socketIO.connect(`${process.env.REACT_APP_CLIENT_URL}`, {
  path: "/socket.io"
});

const Context = React.createContext({
  name: 'Default'
});

const Wrapper = styled.div`
  width: 100%;
  margin: 16px auto;
  background: #fff;
  overflow: auto;
  height: 100vh;
`

const WrapperLogin = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10%;
  max-width: 100%;
`

const Logo = styled.div`
    margin: 20px auto;
    background: url(${logo}) center/contain no-repeat;
    max-width: 180px;
    height: 55px;
`

const { Content, Footer, Sider } = Layout;



function App() {
  const [api, contextHolder] = notification.useNotification();
  const [collapsed, setCollapsed] = useState(false);
  const [user, setUser] = useState({});
  const [isLoggin, setIsLoggin] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    get()
    document.title = "Главная страница - ГрузДок - сервис учета грузоперевозок"
  }, [])

  const openNotification = (placement, description, type) => {

    if (type === 'info') {
      api.info({
        message: `${placement}`,
        description: <Context.Consumer>{() => description}</Context.Consumer>,
        placement: 'topRight',
        duration: 2.5
      });
    } else if (type === 'error') {
      api.error({
        message: `${placement}`,
        description: <Context.Consumer>{() => description}</Context.Consumer>,
        placement: 'topRight',
        duration: 2.5
      });
    }

  };

  const get = async () => {
    if (localStorage.getItem('token')) {
      let res = await AuthService.checkAuth()
      if (res?.data) {
        dispatch(checkIsAuth(res.data));
        setUser(res.data.user)
        setIsLoggin(true);
      }
    } else {
      // console.log('Нет')
    }
  }

  const pages = [
    {
      key: 0,
      component: <MainPage openNotification={openNotification} />,
      path: '/'
    },
    {
      key: 1,
      component: <Companies openNotification={openNotification} />,
      path: '/companies'
    },
    {
      key: 2,
      component: <Registry openNotification={openNotification} socket={socket} />,
      path: '/registry'
    },
    {
      key: 3,
      component: <Agents openNotification={openNotification} />,
      path: '/agents'
    },
    {
      key: 4,
      component: <Orders openNotification={openNotification} socket={socket} />,
      path: '/orders'
    },
    {
      key: 5,
      component: <Payments openNotification={openNotification} socket={socket} />,
      path: '/payments'
    },
    {
      key: 6,
      component: <Drivers openNotification={openNotification} />,
      path: '/drivers'
    },
    {
      key: 7,
      component: <CarsPage openNotification={openNotification} />,
      path: '/cars'
    },
    {
      key: 8,
      component: <Planning openNotification={openNotification} />,
      path: '/planning'
    },
    {
      key: 12,
      component: <Bucket openNotification={openNotification} />,
      path: '/bucket'
    },
    {
      key: 19,
      component: <Settings openNotification={openNotification} />,
      path: '/settings'
    },
    {
      key: 20,
      component: <MainAPage openNotification={openNotification} />,
      path: '/main-a-page'
    }
  ]

  const contextValue = useMemo(
    () => ({
      name: 'Ant Design',
    }),
    [],
  );

  const login = async ({ email, password }) => {
    const res = await dispatch(fetchLogin({ email, password }))
    if (res.error) {
      openNotification('Ошибка!', 'Пароль или пользователя не существует!', 'error')
    } else {
      setUser(res.payload.user)
      setIsLoggin(true)
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <BrowserRouter>
      <Context.Provider value={contextValue}>
        {contextHolder}
        {
          isLoggin ?
            <Layout
              style={{
                minHeight: '100vh',
                maxWidth: '100%',
                margin: '0 auto',
              }}
            >

              <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <Link to='/'><Logo className='demo-logo-vertical' /></Link>
                {
                  user.id && <MenuButton user={user} />
                }
              </Sider>
              <Layout>
                <HeaderWrapper setUser={setUser} openNotification={openNotification} setIsLoggin={setIsLoggin} />
                <Content
                  style={{
                    margin: '0 16px',
                  }}
                >
                  <Wrapper>
                    <Routes>
                      {
                        user.id ?
                          pages.filter(value => user.role.access.includes(+value.key)).map(el => <Route key={el.key} path={el.path} element={el.component} />) :
                          <Route path='/' element={<MainPage />} />
                      }
                    </Routes>
                  </Wrapper>
                </Content>
                <Footer
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Груздок - система для внутреннего учета грузоперевозок ©{moment().year()}
                </Footer>
              </Layout>
            </Layout>
            : <WrapperLogin>
              <Form
                name="basic"
                labelCol={{
                  span: 4,
                }}
                wrapperCol={{
                  span: 22,
                }}
                labelAlign='left'
                style={{
                  width: 400,
                }}
                initialValues={{
                  remember: true,
                }}
                layout='horizontal'
                onFinish={login}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Укажите логин' }]}
                >
                  <Input autoComplete="username"/>
                </Form.Item>
                <Form.Item
                  label="Пароль"
                  name="password"
                  rules={[{ required: true, message: 'Укажите пароль' }]}
                >
                  <Input.Password autoComplete="current-password"/>
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    offset: 20,
                    span: 16,
                  }}
                >
                  <Button type="primary" htmlType="submit">
                    Войти
                  </Button>
                </Form.Item>
              </Form>
            </WrapperLogin>
        }

      </Context.Provider>
    </BrowserRouter>
  );
}

export default App;