import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, Space, Form, Select, ConfigProvider } from 'antd';
import { ButtonPrimary } from '../../styles/buttons';
import ordersService from '../../services/OrdersService';
import RegistryService from '../../services/RegistryService';
import ru_RU from 'antd/lib/locale/ru_RU';
import { useSelector } from 'react-redux';
import AgentsService from '../../services/AgentsService';
import OrdersService from '../../services/OrdersService';
import moment from 'moment';
import dayjs from 'dayjs';
import CompaniesService from '../../services/CompaniesService';

const { RangePicker } = DatePicker;

const RegistryModalFromOrders = ({ closeModalRegistry, openNotification, gettingOrders, socket }) => {

    const userCompany = useSelector((state) => state.userInfo.user.user.company_id);
    const [formEdit] = Form.useForm();
    const [agents, setAgents] = useState();
    const [companies, setCompanies] = useState();

    const getData = useCallback(async () => {
        const agents = await AgentsService.get({ company_id: userCompany }).then(data => data.data);
        setAgents(agents)
        const company = await CompaniesService.get({ company_id: userCompany }).then(data => data.data);
        setCompanies(company)

    }, [userCompany])

    useEffect(() => {
        getData()
    }, [getData])

    const createRegistry = async (values) => {
        const ordersData = await OrdersService.getOrdersForPayment(
            {
                agent: values.agent,
                date: { $gte: dayjs(values.dates[0]).startOf('day'), $lte: dayjs(values.dates[1]).endOf('day') },
                company: values.company,
                status: { $ne: 'deleted' }
            }).then(res => res.data)
        const date = new Date();
        const agents = [];
        const paymentType = [];
        const ordersToRegistry = [];

        const findData = ordersData.filter(el =>
            el?.date === undefined ||
            el?.driver === undefined ||
            el?.number === undefined ||
            el?.bet === undefined ||
            el?.status === 'InvoiceIssued'
        )

        if (findData.length !== 0) {
            openNotification('Ошибка!', `В маршруте №${findData.map(el => el.order)} не заполненны данные`, 'error');
            return;
        }

        const result = {
            agent: values.agent,
            orders: [...new Set(ordersData.map(el => el._id))],
            date: date,
            paymentType: ordersData[0].company._id,
            company_id: userCompany,
        };

        const res = await RegistryService.createOrEdit(result);
        await gettingOrders(true);
        openNotification(res.data.status, res.data.statusInfo, res.data.statusType)
        socket.emit('message', {
            text: 'update',
            room: `Registry ${userCompany}`,
            id: `${socket.id}${Math.random()}`,
            socketID: socket.id,
        });

    }

    const onFinish = async (values) => {
        createRegistry(values)
    };

    const onFinishFailed = (errorInfo) => {
        // openNotification('Ошибка!', errorInfo.errorFields[0].errors[0], 'error')
    };

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    return (
        <Space>
            {
                agents ?
                    <Form
                        form={formEdit}
                        name="RegistryForm"
                        labelCol={{
                            span: 6,
                        }}
                        wrapperCol={{
                            span: 22,
                        }}
                        labelAlign='left'
                        labelWrap={true}
                        style={{
                            width: 450,
                        }}
                        layout='horizontal'
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >

                        <Form.Item
                            label="Контрагент"
                            name="agent"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Выбрать контрагента"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                options={agents && agents.map(el => ({ value: el._id, label: el.type === 'official' ? el.shortName : `${el.name} ${el.secondName} ${el.surName}` }))}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Оплата"
                            name="company"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Выбрать оплату"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                options={companies && companies.map(el => ({ value: el._id, label: el.type === 'official' ? el.shortName : `${el.name} ${el.secondName} ${el.surName}` }))}
                            />
                        </Form.Item>

                        <ConfigProvider locale={ru_RU}>
                            <Form.Item
                                label="Даты"
                                name="dates"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <RangePicker />
                            </Form.Item>
                        </ConfigProvider>



                        <Form.Item
                            wrapperCol={{
                                offset: 20,
                                span: 16,
                            }}
                        >
                            <ButtonPrimary type="primary" htmlType="submit">
                                Сохранить
                            </ButtonPrimary>
                        </Form.Item>
                    </Form> : null
            }

        </Space>
    );
};

export default RegistryModalFromOrders;