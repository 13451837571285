import React, { useEffect, useState, useRef } from 'react';
import DriversTable from './DriversTable';
import { useSelector } from 'react-redux';
import { ButtonsWrapper, ButtonPrimary } from '../../styles/buttons';
import { Tabs } from 'antd';
import driverService from '../../services/DriverService';
import DriversSettings from '../../services/DriversSettings';
import { Link } from 'react-router-dom';
import { PageWrapper } from '../../components/StyledComponents/styled';


const Drivers = ({ openNotification }) => {
    const [open, setOpen] = useState(false);
    const [drivers, setDrivers] = useState('');
    const driversType = useRef([]);

    const userCompany = useSelector((state) => state.userInfo.user.user.company_id);

    useEffect(() => {
        getTypes();
        getDrivers();
        document.title = "Водители - ГрузДок - сервис учета грузоперевозок"

    }, [])

    const getTypes = async () => {
        const res = await DriversSettings.get({ company_id: userCompany })
        driversType.current = res.data;
    }

    const showModal = () => {
        setOpen(true);
    };

    const getDrivers = async () => {
        let drivers = await driverService.get({ company_id: userCompany });
        console.log(drivers)
        setDrivers(drivers.data.map(el => ({ ...el, key: el._id })));
    }

    const deleteDriver = async (data) => {
        await driverService.delete({ _id: data });
        await getDrivers();
    }

    const recoverDriver = async (data) => {
        await driverService.recover({ _id: data });
        await getDrivers();
    }
    
    const onChange = (key) => {
        getDrivers(key);
    }

    return (
        <PageWrapper>
            <ButtonsWrapper>
                <ButtonPrimary onClick={showModal}
                >
                    Добавить водителя
                </ButtonPrimary>
            </ButtonsWrapper>
            {
                driversType.current.length ?
                    <Tabs defaultActiveKey="1" items={
                        driversType.current.map((el, index) => ({
                            key: index,
                            label: el.typeName,
                            children: <DriversTable getDrivers={getDrivers} deleteDriver={deleteDriver} data={drivers.filter(driver => driver.type === el._id)} setDrivers={setDrivers} setOpen={setOpen} open={open} userCompany={userCompany} openNotification={openNotification} types={driversType.current} recoverDriver={recoverDriver}/>
                        }))
                    } onChange={onChange} /> :

                    <>
                        Перейдите в <Link to='/settings'>Настройки</Link> и создайте тип водителей (доступно только Администратору)
                    </>
            }

        </PageWrapper>
    )
};

export default Drivers;